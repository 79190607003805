<template>
    <div class="form-group" :class="{error: data.error}">
        <label class="form-group__label" for="" v-if="data.label">{{ data.label }}<small v-if="data.suffix" class="label-suffix">&nbsp; {{data.suffix}}</small><slot name="label"></slot></label>
        <div class="form-group__input-container">
            <input class="form-group__input"
                   :class="{invalid: !validateFormula(modelValue)}"
                v-model="modelValue"
                :required="data.required"
                type="text"
                :min="data.min"
                :max="data.max"
                :placeholder="data.placeholder"
                :disabled="data.disabled"
                :tabindex="data.tabindex"
                @focus="data.error = '', $emit('focus')"
                @blur="validateFormula(modelValue),  $emit('blur')"
                @input="input"
                ref="formulaInput"
                @keypress="isAllowed($event)"
                @keydown="handleInput($event)"
                @onselectstart="(e) => {return false}"
            >
          <Popover v-slot="{ open }" class="popover">
            <PopoverButton :disabled="data.disabled" as="button"
                           class="btn btn_default popover-button tw-p-0 tw-border-0">
              <div class="tw-space-x-1.5">
                  <span v-if="open">
                      <svg-icon name="solid/chevron-up"></svg-icon>
                  </span>
                <span v-else>
                      <svg-icon name="solid/arrow-down-from-dotted-line"></svg-icon>
                  </span>
              </div>
            </PopoverButton>

            <transition name="popover">
              <!-- eslint-disable-next-line vue/no-unused-vars -->
              <PopoverPanel v-slot="{ close }" class="popover-panel tw-w-30 tw-bg-gray-100- tw-shadow-lg">
                <div
                  class="tw-grid tw-grid-cols-1 tw-gap-1 tw-text-sm tw-bg-gray-300- tw-divide-y tw-divide-red-500">
                  <button v-for="(formula, k) in placeholders"
                          :key="k"
                          class="btn btn_default popover-menu-item"
                          @click="selectPlaceholder(formula)"
                  >
                    {{ formula }}
                  </button>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
        <div class="form-group__error" v-if="data.error">{{ data.error }}</div>

      </div>
</template>

<script>
import {ref} from "vue"
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {Parser} from 'expr-eval'

export default({
    props: ['data', 'modelValue', 'filter', 'placeholders'],
    components: {Popover, PopoverButton, PopoverPanel},
    setup(props, { emit }) {
      const formulaInput = ref(null)

        function input(event) {
            let data = event.target.value
            if(props.filter) data = props.filter(data)

            emit('update:modelValue', data)
        }

        function selectPlaceholder(text){
          const p = '{' + text + '}'

          const self = this;
          var tArea = formulaInput.value;
          // filter:
          if (0 == p) {
            return;
          }

          // get cursor's position:
          var startPos = tArea.selectionStart,
            endPos = tArea.selectionEnd,
            cursorPos = startPos,
            tmpStr = tArea.value;

          // insert:
          self.txtContent = tmpStr.substring(0, startPos) + p + tmpStr.substring(endPos, tmpStr.length);
          emit('update:modelValue', self.txtContent)

        }

        function isAllowed(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 47 && charCode < 58)  //Numbers
            || (charCode >= 40 && charCode <= 43)
            || (charCode >= 45 && charCode <= 47))
          {
            return true
          }

          evt.preventDefault()
          return false
        }

        function handleInput(e) {


          if (e.key === "Backspace" || e.key === "Delete") {

            var tArea = formulaInput.value;
            // filter:

            // get cursor's position:
            var startPos = tArea.selectionStart,
              endPos = tArea.selectionEnd,
              cursorPos = startPos,
              tmpStr = tArea.value;

            var lastOpenMoustasheBefore = tmpStr.substring(0, cursorPos).lastIndexOf('{')
            var lastCloseMoustasheBefore = tmpStr.substring(0, cursorPos).lastIndexOf('}')

            var firstOpenMoustasheAfter = cursorPos + tmpStr.substring(cursorPos).indexOf('{')
            var firstCloseMoustasheAfter = cursorPos + tmpStr.substring(cursorPos).indexOf('}')

              if (e.key === 'Backspace' && lastCloseMoustasheBefore == cursorPos - 1) {
                tmpStr = tmpStr.substring(0, lastOpenMoustasheBefore) + tmpStr.substring(lastCloseMoustasheBefore + 1)
                cursorPos = lastOpenMoustasheBefore
              }
              else if (e.key === 'Delete' && firstOpenMoustasheAfter == cursorPos) {
                tmpStr = tmpStr.substring(0, firstOpenMoustasheAfter) + tmpStr.substring(firstCloseMoustasheAfter + 1)
              }
              else if (lastOpenMoustasheBefore > lastCloseMoustasheBefore){
                tmpStr = tmpStr.substring(0, lastOpenMoustasheBefore) + tmpStr.substring(firstCloseMoustasheAfter + 1)
                cursorPos = lastOpenMoustasheBefore
              }

            if (tmpStr !== tArea.value) {
              emit('update:modelValue', tmpStr)
              tArea.selectionStart = cursorPos
              tArea.selectionEnd = cursorPos
              return e.preventDefault();
            }// Don't do anything to the input value

          }
        }

        function validateFormula(f){
          let exp = f
          const parser = new Parser()

          if (exp == '' || exp == null){
            return true
          }

          props.placeholders.forEach((p) => {
            exp = exp.replaceAll('{' + p + '}', '9999')
          })
          try{
            parser.evaluate(exp)
          }
          catch (ex) {
            console.log(ex)
            props.data.error = 'Invalid formula'
            return false
          }

          return true
        }

        return {
          formulaInput,
          input,
          selectPlaceholder,
          isAllowed,
          handleInput,
          validateFormula
        }
    },
})
</script>


<style scoped lang="scss">
.form-group {
    width: auto;
    &__label {
        font-weight: bold;
        font-size: 17px;
        line-height: 140%;
        display: block;
        margin: 0 0 2px 4px;
        text-align: left;
        white-space: nowrap;
        display: flex;
        align-items: center;
        ::v-deep .help_topic {
            margin-left: 5px;
            display: inline-block;
        }
    }
    &__input {
        border: 1px solid rgba(11, 53, 83, 0.2);
        border-radius: 2px;
        height: 40px;
        width: 100%;
        padding: 0 13px 0 15px;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select:none;
        user-select:none;
        -o-user-select:none;
      padding-right: 25px;

        &::placeholder {
            font-style: italic;
            font-weight: normal;
        }
        &-container {
            position: relative;
          width: 100%;
        }
        &.invalid{
          border-color: #C0392B;
        }
    }
    &__error {
        font-size: 14px;
        line-height: 140%;
        color: #DB5151;
        text-align: left;
    }
}

.popover-button{
  position: absolute;
  right: 10px;
  top: -25px;
  color: var(--PrimaryColour)
}

.popover-panel{
  border: 1px solid var(--PrimaryColour);
  padding: 3px;
  margin-top: 0;
}

</style>
